import logo from './logo.svg';
import './App.css';
import API, { graphqlOperation } from '@aws-amplify/api';

API.configure();

const apiName = 'executePythonCode';
const path = '/test'; //replace this with the path you have configured on your API
const myInit = {
    body: {}, // replace this with attributes you need
    headers: {"Content-type": "application/x-www-form-urlencoded"}, // OPTIONAL
};

function runTests(e) {
  e.target.style.display = "none";
  document.getElementById("results").innerHTML = "Running...";

  const code = document.getElementById("code").value;

  console.log(code);

  var apiRequest = {
                    body: {code: code},
                    headers: myInit.headers
                    };

  API
  .post(apiName, path, apiRequest)
  .then(response => {
    // Add your code here
    console.log(response);
    console.log("API post response.then");

    document.getElementById("results").innerHTML = response.message;
    e.target.style.display = "initial";

  })
  .catch(error => {
    console.log(error.response);
  });

  console.log("finish runTests");
}

// let xhr = new XMLHttpRequest();

// xhr.onreadystatechange = () => {
//     if (xhr.readyState == 4 && xhr.status == 200) {
//         document.getElementById("results").innerHTML = xhr.responseText;
//         e.target.style.display = "initial";
//     }
// }

// xhr.open("POST", "http://localhost:5000/test/");
// xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
// xhr.send("code=" + code);

// console.log(e);
// console.log(e.target);

function App() {
  return (
    <div className="container">
        <div className="question">
            <h2><strong>QUESTION</strong></h2>
            Find the maximum number in an array of integers.
            <br/> For example, in the array [1, -3, 5], the maximum number is 5.
        </div>
        <div className="code-area">
            <textarea rows="20" className="input" id="code" autofocus>def solution(arr):</textarea>
            <br/><br/>
            <button onClick={runTests}>RUN TESTS</button>
            <br/><br/>
            <span id="results"></span>
        </div>
    </div>
  );
}

export default App;
